// Import the functions you need from the SDKs you need
// https://firebase.google.com/docs/web/setup#available-libraries //
import * as Firebase from 'firebase/app'
import * as Analytics from 'firebase/analytics'
import * as Auth from 'firebase/auth'
import * as Firestore from 'firebase/firestore'
import * as Storage from 'firebase/storage'
import * as Functions from 'firebase/functions'
import { initializeApp } from 'firebase/app'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_API_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const App = initializeApp(firebaseConfig)
const Database = Firestore.getFirestore(App)
const usersDbName = process.env.VUE_APP_DEMO === 'demo' ? 'usersDemo' : 'users'

// Initialize Functions References
const functions = Functions.getFunctions(App, 'europe-west3')
const HelpFormFunction = Functions.httpsCallable(functions, 'help-form')
const HelpLogFunction = Functions.httpsCallable(functions, 'help-log')

const UsersUpdateEmail = Functions.httpsCallable(
  functions,
  'users-update-email'
)
const UsersUpdatePassword = Functions.httpsCallable(
  functions,
  'users-update-password'
)
const UsersPlanRetrieve = Functions.httpsCallable(
  functions,
  'users-plan-retrieve'
)
const UsersIp = Functions.httpsCallable(functions, 'users-ip')

const NotificationsCreate = Functions.httpsCallable(
  functions,
  'notifications-create',
  { timeout: 300000 }
)
const NotificationsRetrieve = Functions.httpsCallable(
  functions,
  'notifications-retrieve',
  { timeout: 540000 }
)
const NotificationsOpen = Functions.httpsCallable(
  functions,
  'notifications-open'
)
const NotificationsSynchronize = Functions.httpsCallable(
  functions,
  'notifications-synchronize'
)
const NotificationsItem = Functions.httpsCallable(
  functions,
  'notifications-item'
)
const NotificationsAck = Functions.httpsCallable(functions, 'notifications-ack')
const NotificationsAnnexe = Functions.httpsCallable(
  functions,
  'notifications-annexe'
)
const NotificationsExtra = Functions.httpsCallable(
  functions,
  'notifications-extra'
)
const GetSharedNotification = Functions.httpsCallable(
  functions,
  'notifications-shared-get'
)
const NotificationsUploadFiles = Functions.httpsCallable(
  functions,
  'notifications-uploadFiles',
  { timeout: 240000 }
)
const NotificationsUploadExtraFiles = Functions.httpsCallable(
  functions,
  'notifications-uploadExtraFiles',
  { timeout: 240000 }
)

const Doc = (path) => Firestore.doc(Database, path)

// Initialize Firestore References

const UsersCollection = Firestore.collection(Database, usersDbName)
const UsersDoc = (userId) => Firestore.doc(Database, usersDbName, userId)
const UserIsNonClientByEmail = Functions.httpsCallable(
  functions,
  'users-isNonClientByEmail'
)
const UserIsSingleSignOn = Functions.httpsCallable(
  functions,
  'users-validateUserSingleSignOn'
)

const GetCompanyByCif = Functions.httpsCallable(
  functions,
  'users-validateCompanyByCif'
)

const NotificationsCollection = (userId) =>
  Firestore.collection(Database, usersDbName, userId, 'notifications')

const NotificationsDoc = (userId, notificationId) =>
  Firestore.doc(Database, usersDbName, userId, 'notifications', notificationId)

// Operators reference
const UserOperatorsQuery = (userId) =>
  Firestore.query(
    UsersCollection,
    Firestore.where('parentRef', '==', UsersDoc(userId)),
    Firestore.where('tipo', '==', 'operator')
  )

// User children reference
const UserChildrenQuery = (userId) =>
  Firestore.query(
    UsersCollection,
    Firestore.where('parentRef', '==', UsersDoc(userId))
  )

// User clients reference
const UserClientsQuery = (userId) =>
  Firestore.query(
    UsersCollection,
    Firestore.where('parentRef', '==', UsersDoc(userId)),
    Firestore.where('tipo', '==', 'clienteDespacho')
  )

const OperatorDoc = (operatorId) =>
  Firestore.doc(Database, `${usersDbName}/${operatorId}`)

const operatorsCollection = Firestore.collectionGroup(Database, 'operators')

const CreateOperator = Functions.httpsCallable(functions, 'operators-create')
const CreateOperatorBulk = Functions.httpsCallable(
  functions,
  'operators-createBulk',
  { timeout: 3600000 }
)
const UpdateOperatorEmail = Functions.httpsCallable(
  functions,
  'operators-update-email'
)
const UpdateOperatorPassword = Functions.httpsCallable(
  functions,
  'operators-update-password'
)
const UpdateOperatorMultifactor = Functions.httpsCallable(
  functions,
  'operators-update-multifactor'
)
const DisableUserOperator = Functions.httpsCallable(
  functions,
  'operators-disable'
)

const UpdateUserCertificate = Functions.httpsCallable(
  functions,
  'users-certificate-update',
  { timeout: 180000 }
)

const DeleteUserCertificate = Functions.httpsCallable(
  functions,
  'users-certificate-delete'
)

// Operator's trace
const TraceCollection = (userId) =>
  Firestore.collection(Database, usersDbName, userId, 'trace')
const TraceDoc = (userId, traceId) =>
  Firestore.doc(Database, usersDbName, userId, 'trace', traceId)

// Wallets
const WalletCollection = (userId) =>
  Firestore.collection(Database, usersDbName, userId, 'wallets')
const WalletDoc = (userId, walletId) =>
  Firestore.doc(Database, usersDbName, userId, 'wallets', walletId)
const CreateWallet = Functions.httpsCallable(functions, 'clients-createWallet')
const DeleteWallet = Functions.httpsCallable(
  functions,
  'clients-deleteWallet',
  { timeout: 240000 }
)
const UploadCertificate = Functions.httpsCallable(
  functions,
  'clients-uploadCertificate',
  { timeout: 240000 }
)
const UpdatePin = Functions.httpsCallable(functions, 'clients-updatePin')
const UpdateWallet = Functions.httpsCallable(
  functions,
  'clients-updateWallet',
  { timeout: 240000 }
)

// Signatures
const SignaturesCollection = (userId) =>
  Firestore.collection(Database, usersDbName, userId, 'signatures')
const SignaturesDoc = (userId, signatureId) =>
  Firestore.doc(Database, usersDbName, userId, 'signatures', signatureId)
const CreateSignature = Functions.httpsCallable(
  functions,
  'signatures-create',
  { timeout: 240000 }
)
const UpdateSignature = Functions.httpsCallable(
  functions,
  'signatures-update',
  { timeout: 240000 }
)
const DeleteSignature = Functions.httpsCallable(
  functions,
  'signatures-delete',
  { timeout: 180000 }
)
const SignaturesItem = Functions.httpsCallable(functions, 'signatures-item')
const SignSignatureRequest = Functions.httpsCallable(
  functions,
  'signatures-sign',
  { timeout: 180000 }
)
const SendSignatureOTP = Functions.httpsCallable(
  functions,
  'signatures-sendOTP'
)

// Clients
const ClientCreate = Functions.httpsCallable(functions, 'clients-create')

// CIFs
const CreateCifBulk = Functions.httpsCallable(
  functions,
  'companies-createBulk',
  { timeout: 3600000 }
)
const CreateCif = Functions.httpsCallable(functions, 'companies-create', {
  timeout: 120000,
})
const UpdateCif = Functions.httpsCallable(functions, 'companies-update', {
  timeout: 120000,
})
const DeleteCif = Functions.httpsCallable(functions, 'companies-delete', {
  timeout: 540000,
})
const ProgramDelete = Functions.httpsCallable(
  functions,
  'companies-programDelete'
)
const ExportDmsNotifications = Functions.httpsCallable(
  functions,
  'notifications-export'
)

const CIFsCollection = (userId) =>
  Firestore.collection(Database, usersDbName, userId, 'cifs')
const CIFDoc = (userId, cifId) =>
  Firestore.doc(Database, usersDbName, userId, 'cifs', cifId)

// Massive actions
const ExecuteMassiveAction = Functions.httpsCallable(
  functions,
  'massiveActions-enqueue'
)

// Filters
const FiltersCollection = () => Firestore.collection(Database, 'filters')
const FilterDoc = (rolId) => Firestore.doc(Database, 'filters', rolId)

// User Filter
const UserFiltersCollection = (userId) =>
  Firestore.collection(Database, usersDbName, userId, 'filters')
const UserFiltersDoc = (userId, filterId) =>
  Firestore.doc(Database, usersDbName, userId, 'filters', filterId)

// Automated task
const AutomatedTasksCollection = (userId) =>
  Firestore.collection(Database, usersDbName, userId, 'automatedTasks')

const AutomatedTaskDoc = (userId, automatedTaskId) =>
  Firestore.doc(
    Database,
    usersDbName,
    userId,
    'automatedTasks',
    automatedTaskId
  )

const AutomatedTaskHistoryCollection = (userId, automatedTaskId) =>
  Firestore.collection(
    Database,
    usersDbName,
    userId,
    'automatedTasks',
    automatedTaskId,
    'history'
  )

// Groups
const GroupsCollection = (userId) =>
  Firestore.collection(Database, usersDbName, userId, 'groups')

const GroupDoc = (userId, groupId) =>
  Firestore.doc(Database, usersDbName, userId, 'groups', groupId)

const GroupCheckExistence = Functions.httpsCallable(
  functions,
  'groups-checkExistence'
)

// News
const NewsCollection = () => Firestore.collection(Database, 'news')
const NewsDoc = (id) => Firestore.doc(Database, 'news', id)

// History references
const HistoryCollection = (userId, assetType, docID) =>
  Firestore.collection(
    Database,
    usersDbName,
    userId,
    assetType,
    docID,
    'history'
  )

const HistoryDoc = (userId, assetType, docID, historyId) =>
  Firestore.doc(
    Database,
    usersDbName,
    userId,
    assetType,
    docID,
    'history',
    historyId
  )

// Notification Share Codes references
const ShareCodesCollection = (userId, notificationId) =>
  Firestore.collection(
    Database,
    usersDbName,
    userId,
    'notifications',
    notificationId,
    'shared'
  )

const ShareCodesDoc = (userId, notificationId, shareCodeId) =>
  Firestore.doc(
    Database,
    usersDbName,
    userId,
    'notifications',
    notificationId,
    'shared',
    shareCodeId
  )

const MetadataVersion = Firestore.doc(Database, 'settings', 'webAppMetadata')

const LegalSettings = Firestore.doc(Database, 'settings', 'legal')

const GetDocFromReference = async (reference) => {
  if (!reference) return
  const doc = await Firestore.getDoc(reference)
  return { id: doc.id, ...doc.data() }
}

export {
  Firebase,
  App,
  Analytics,
  Auth,
  Firestore,
  Database,
  Storage,
  Functions,
  Doc,
  //Users
  UsersCollection,
  UsersDoc,
  UserIsNonClientByEmail,
  UserIsSingleSignOn,
  UpdateUserCertificate,
  DeleteUserCertificate,
  NotificationsCollection,
  NotificationsDoc,
  ExecuteMassiveAction,
  FiltersCollection,
  FilterDoc,
  GetCompanyByCif,
  // Automated tasks
  AutomatedTasksCollection,
  AutomatedTaskDoc,
  AutomatedTaskHistoryCollection,
  // Groups
  GroupsCollection,
  GroupDoc,
  GroupCheckExistence,
  NewsCollection,
  NewsDoc,
  UsersUpdateEmail,
  UsersUpdatePassword,
  UsersPlanRetrieve,
  UsersIp,
  //Operators
  UserChildrenQuery,
  UserClientsQuery,
  UserFiltersCollection,
  UserFiltersDoc,
  OperatorDoc,
  operatorsCollection,
  UserOperatorsQuery,
  CreateOperator,
  CreateOperatorBulk,
  UpdateOperatorEmail,
  UpdateOperatorPassword,
  UpdateOperatorMultifactor,
  DisableUserOperator,
  //Trace
  TraceCollection,
  TraceDoc,
  //Wallets
  WalletCollection,
  WalletDoc,
  CreateWallet,
  DeleteWallet,
  //Signatures
  SignaturesCollection,
  SignaturesDoc,
  CreateSignature,
  UpdateSignature,
  DeleteSignature,
  SignaturesItem,
  SignSignatureRequest,
  SendSignatureOTP,
  //CIFS
  CreateCifBulk,
  CreateCif,
  UploadCertificate,
  UpdatePin,
  UpdateWallet,
  UpdateCif,
  DeleteCif,
  ExportDmsNotifications,
  ProgramDelete,
  CIFsCollection,
  CIFDoc,
  // Clients
  ClientCreate,
  //Notifications
  NotificationsCreate,
  NotificationsRetrieve,
  NotificationsOpen,
  NotificationsSynchronize,
  NotificationsItem,
  NotificationsAck,
  NotificationsAnnexe,
  NotificationsExtra,
  GetSharedNotification,
  NotificationsUploadFiles,
  NotificationsUploadExtraFiles,
  // History
  HistoryCollection,
  HistoryDoc,
  // Share codes
  ShareCodesCollection,
  ShareCodesDoc,
  //Settings
  HelpFormFunction,
  HelpLogFunction,
  MetadataVersion,
  LegalSettings,
  GetDocFromReference,
}
