<template>
  <div v-if="progress.isDisplayed">
    <v-progress-linear
      :model-value="progress.movement ? undefined : 100"
      :indeterminate="progress.movement"
      :color="progress.color"
      rounded
      :height="progress.message ? progress.height : 5"
    >
      <strong> {{ $t(progress.message) }} </strong>
      <a
        v-if="progress.link"
        @click="$router.push(progress.link.route)"
        style="text-decoration: underline; margin-left: 10px"
      >
        <b>{{ $t(progress.link.text) }}</b>
      </a>
    </v-progress-linear>
  </div>
</template>

<script>
export default {
  props: {
    progress: Object,
  },
}
</script>
