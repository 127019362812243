import { Auth, Firestore, UsersDoc } from '@/firebase-exports'
import { getOperatorFilters } from '@/services/operator-service'
import {
  getParentAttributes,
  getCifs,
  getOperatorGroups,
} from '@/services/user-service'
import getUserPages from '@/utils/get-user-pages'
import { planSubscription } from '@/services/plan-service'
import { notificationsSubscription } from '@/services/notification-service'
import { newsSubscription } from '@/services/news-service'
import { signaturesSubscription } from '@/services/signatures-service'
import { setBrand, resetBrand } from '@/utils/brand-utils'

import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import { listGroups } from '@/services/groups-service'

const actions = {
  async fetchUser({ dispatch, commit }) {
    try {
      const firebaseUser = await Auth.getAuth().currentUser
      Auth.getAuth().currentUser.getIdToken(false)
      if (firebaseUser) {
        var userUnsubscribe = Firestore.onSnapshot(
          UsersDoc(firebaseUser.uid),
          async (docSnap) => {
            if (!docSnap.exists()) {
              // Usuario no existe
              commit('setSnackbar', {
                position: 'top',
                type: 'error',
                message: i18n.t('unauthorized'),
              })
              dispatch('logoutUnauthorized')
            } else {
              var firestoreUser = docSnap.data()

              // Delete some back variables that could be unsafe to store in front
              delete firestoreUser.password
              delete firestoreUser.passwordEmpresa
              delete firestoreUser.redmineKey
              delete firestoreUser.redmineProject

              let groups = []
              let operatorRoles = []
              let parentAttributes = {}

              let cifs = await getCifs(
                firestoreUser.parentRef
                  ? firestoreUser.parentRef.id
                  : firebaseUser.uid,
                firestoreUser.parentRef && firestoreUser
              )

              // Si es operador
              if (firestoreUser.parentRef) {
                // Obtenemos los filters del operador
                operatorRoles = await getOperatorFilters(firestoreUser)
                parentAttributes = await getParentAttributes(
                  firestoreUser.parentRef.id
                )
                groups = await getOperatorGroups(firestoreUser)
              }

              var firestoreCopy = {
                ...firestoreUser,
                id: firebaseUser.uid,
                groups,
                cifs,
                operatorRoles,
                allNotifications: false,
                ...parentAttributes,
              }

              // Suscripción del plan
              await planSubscription(
                firestoreUser.parentRef
                  ? firestoreUser.parentRef.id
                  : firebaseUser.uid,
                firestoreCopy
              )

              // Suscripción de las notificaciones
              if (firestoreUser.notificacionesActivas)
                await notificationsSubscription(
                  firestoreUser.parentRef
                    ? firestoreUser.parentRef.id
                    : firebaseUser.uid,
                  firestoreCopy
                )

              // Suscripcion de firmas
              if (firestoreUser.firmasActivas)
                await signaturesSubscription(
                  firestoreUser.parentRef
                    ? firestoreUser.parentRef.id
                    : firebaseUser.uid,
                  firestoreCopy
                )

              // Personalizar marca blanca
              if (firestoreCopy.customBrand) setBrand(firestoreCopy.customBrand)
              else if (store.state.brand) resetBrand()

              dispatch('configureUser', firestoreCopy)

              // Check de certificados para mostrar la barra roja en la parte superior
              if (store.getters.isAdminUser) {
                // Usuario sin activar, sin certificado
                if (firestoreCopy.needsCertEmpresa) {
                  commit('setProgress', {
                    message: 'uploadCertificateWarning',
                    height: 50,
                    color: 'error',
                    movement: false,
                    link: {
                      text: 'redirectToConfiguration',
                      route: '/settings',
                    },
                  })
                }

                // Usuario con el certificado principal caducado
                if (firestoreCopy.certificateExpiration) {
                  const certExpiration = firestoreCopy.certificateExpiration
                    .toDate()
                    .toISOString()
                    .slice(0, 10)
                  const today = new Date().toISOString().slice(0, 10)
                  if (certExpiration < today)
                    commit('setProgress', {
                      message: 'certificateExpiration.expiredWarning',
                      height: 50,
                      color: 'error',
                      movement: false,
                      link: {
                        text: 'redirectToConfiguration',
                        route: '/settings',
                      },
                    })
                }

                // Usuario con el certificado alternativo caducado
                firestoreCopy.alternativeCertificates.forEach((certificate) => {
                  const certExpiration = certificate.expiration
                    .toDate()
                    .toISOString()
                    .slice(0, 10)
                  const today = new Date().toISOString().slice(0, 10)
                  if (certExpiration < today)
                    commit('setProgress', {
                      message: 'certificateExpiration.expiredWarning',
                      height: 50,
                      color: 'error',
                      movement: false,
                      link: {
                        text: 'redirectToConfiguration',
                        route: '/settings',
                      },
                    })
                })
              }

              firestoreUser.id = firebaseUser.uid
              const tokenExpiration =
                firebaseUser.stsTokenManager.expirationTime
              const currentDate = new Date().getTime()
              const expirationTimeout = tokenExpiration - currentDate
              setTimeout(async () => {
                await dispatch('fetchUser')
              }, expirationTimeout)
              if (!router.currentRoute.meta.userCanAccess()) {
                router.push('/')
              }

              groups =
                groups.length > 0 ? groups : await listGroups(firebaseUser.uid)

              // If the user is an operator, groups have already been
              if (groups.some((group) => group.unregisteredCifs?.length > 0)) {
                // If it is admin, set the pending dot in the cifs button
                if (firestoreUser.parentRef && firestoreUser.adminPermissions)
                  commit('setPending', { cifs: true })
                // If it is superadmin, set the pending dot in the groups button
                else commit('setPending', { groups: true })
              }

              // Suscripción de las noticias
              if (!store.getters.isDemoEnv) await newsSubscription()
            }
          },
          (error) => {
            throw error
          }
        )
        return userUnsubscribe
      } else {
        throw new Error('User is not authenticated')
      }
    } catch (err) {
      dispatch('configureUser', null)
      throw err
    }
  },
  configureUser({ commit }, userData) {
    commit('setUser', userData)

    if (!userData) commit('setNavigationPages', {})
    else {
      var userPages = getUserPages()
      commit('setNavigationPages', userPages)
    }
  },
  async logout({ dispatch }) {
    dispatch('killListeners')
    await Auth.signOut(Auth.getAuth())
    dispatch('configureUser', null)
    dispatch('resetStore')
    router.replace('/')
    window.location.reload()
  },
  async logoutUnauthorized() {
    await Auth.signOut(Auth.getAuth())
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  },
  killListeners({ commit }) {
    commit('killListenersMutation')
  },
  resetStore({ commit }) {
    commit('resetState')
  },
}

export default actions
