<template>
  <v-card class="pa-3" outlined>
    <v-container>
      <v-row justify="center">
        <span class="headline mb-1"> {{ $t('licenses') }} </span>
      </v-row>
      <v-row justify="center">
        <span>
          {{ $t('expirationLicense') }}
          <b>
            {{ $d(plan.expirationLicense.toDate(), 'i18nDate') }}
          </b>
        </span>
      </v-row>
      <v-row justify="center">
        <v-col
          v-if="plan"
          cols="12"
          lg="6"
          style="text-align: center"
          class="subtitle-1"
        >
          <span v-if="isNotificacionesActivasEmpresa">
            <b> {{ $t('notificationsLicense') }} </b>
          </span>
          <span v-else>
            <b> {{ $t('notificationsNotPurchased') }} </b>
          </span>
          <br />

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span class="ma-1" v-bind="attrs" v-on="on">
                <AppPlanProgress
                  icon="mdi-mailbox"
                  :current="plan.notificationsUsed"
                  :max="plan?.notificationsMax"
                  :disabled="!isNotificacionesActivasEmpresa"
                />
              </span>
            </template>
            <span> {{ $t('notificationsLicenses') }} </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span class="ma-1" v-bind="attrs" v-on="on">
                <AppPlanProgress
                  icon="mdi-city"
                  :current="cifsCountNotifications"
                  :max="plan?.notificationsMaxCifs"
                  :disabled="!isNotificacionesActivasEmpresa"
                />
              </span>
            </template>
            <span> {{ $t('notificationCifsLicenses') }} </span>
          </v-tooltip>
        </v-col>
        <v-col
          v-if="plan"
          cols="12"
          lg="6"
          style="text-align: center"
          class="subtitle-1"
        >
          <span v-if="isFirmasActivasEmpresa">
            <b> {{ $t('signaturesLicense') }} </b>
          </span>
          <span v-else>
            <b> {{ $t('signaturesNotPurchased') }} </b>
          </span>
          <br />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span class="ma-1" v-bind="attrs" v-on="on">
                <AppPlanProgress
                  icon="mdi-draw"
                  :current="plan.signaturesUsed"
                  :max="plan?.signaturesMax"
                  :disabled="!isFirmasActivasEmpresa"
                />
              </span>
            </template>
            <span> {{ $t('signaturesLicenses') }} </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span class="ma-1" v-bind="attrs" v-on="on">
                <AppPlanProgress
                  icon="mdi-account-key"
                  :current="certsCountSignatures"
                  :max="plan?.signaturesMaxCerts"
                  :disabled="!isFirmasActivasEmpresa"
                />
              </span>
            </template>
            <span> {{ $t('signatureCertsLicenses') }} </span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import AppPlanProgress from '@/components/commons/AppPlanProgress'
import { getCifsSubscriptionSimplified } from '@/services/cifs-service'
import { mapGetters } from 'vuex'

export default {
  props: {
    plan: Object,
  },
  components: {
    AppPlanProgress,
  },
  async mounted() {
    const userId = this.isOperatorUser
      ? this.$store.state.user.parentRef.id
      : this.$store.state.user.id

    this.cifsUnsubscribe = await getCifsSubscriptionSimplified(userId, this)
  },
  destroyed() {
    if (this.cifsUnsubscribe) this.cifsUnsubscribe()
  },
  data() {
    return {
      cifs: [],
      cifsUnsubscribe: () => {},
    }
  },
  computed: {
    ...mapGetters([
      'isAdminUser',
      'isOperatorUser',
      'isEmpresaUser',
      'isNotificacionesActivasEmpresa',
      'isFirmasActivasEmpresa',
    ]),
    cifsCountNotifications() {
      if (!this.isEmpresaUser) {
        return this.cifs.reduce((acc, cif) => {
          return (
            acc +
            (cif.notificacionesActivas ? (cif.granDestinatario ? 10 : 1) : 0)
          )
        }, 0)
      } else {
        return this.cifs.filter((c) => c.notificacionesActivas).length
      }
    },
    certsCountSignatures() {
      return this.cifs.reduce(
        (acc, cif) =>
          acc + (cif.allowedToSign ? Object.keys(cif.allowedToSign).length : 0),
        0
      )
    },
  },
}
</script>

<style lang="scss" scoped></style>
