<template>
  <div>
    <v-card class="mx-auto" data-v-step="5" outlined>
      <v-container>
        <v-row justify="space-between" align="center">
          <v-col cols="6" xl="auto" class="order-0">
            <v-icon class="mr-1">mdi-city</v-icon>
            <span class="headline">{{ $t('cifs') }}</span>
            <span class="subtitle-2 font-weight-light">
              ({{ cifsCount }})
            </span>

            <DialogButton
              section="companies"
              :hideButton="isOperatorUser !== undefined"
              :text="
                isOperatorUser
                  ? $t('documentation.shortTexts.companiesOperator')
                  : $t('documentation.shortTexts.companiesAdmin')
              "
            />
          </v-col>
          <v-col
            v-if="
              (isSuperAdminUser ||
                isSuperOperatorUser ||
                isAdminAndCanManageCompanies) &&
              !isInGroupUser
            "
            cols="12"
            xl="7"
            class="order-2 order-xl-1"
          >
            <v-row align="center" class="pt-3">
              <v-col cols="12" md="6">
                <AppPlanProgress
                  :class="isMobile ? 'ml-4 mr-4' : ''"
                  icon="mdi-mailbox"
                  :current="cifsCountNotifications"
                  :max="notificationsMaxCifs"
                  :disabled="!isNotificacionesActivasEmpresa"
                />
              </v-col>
              <v-col cols="12" md="6">
                <AppPlanProgress
                  :class="isMobile ? 'ml-4 mr-4' : ''"
                  icon="mdi-draw"
                  :current="certsCountSignatures"
                  :max="signaturesMaxCerts"
                  :disabled="!isFirmasActivasEmpresa"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col
            class="d-flex justify-end align-center order-1 order-xl-2"
            cols="6"
            xl="auto"
          >
            <v-tooltip
              bottom
              v-if="
                isSuperAdminUser ||
                isSuperOperatorUser ||
                isAdminAndCanManageCompanies
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="1"
                  fab
                  small
                  class="primary secondary--text ma-1"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="loading"
                  id="export-data"
                  @click.stop="openCifExportDialog({}, 'exportCifs')"
                >
                  <v-icon> mdi-file-export </v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('companiesActions.title.exportCifs') }}
              </div>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="
                !isEmpresaUser &&
                (isSuperAdminUser ||
                  isSuperOperatorUser ||
                  isAdminAndCanManageCompanies)
              "
            >
              <template
                v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }"
              >
                <v-btn
                  v-if="isNotificacionesActivasEmpresa"
                  class="primary secondary--text ma-1"
                  fab
                  small
                  elevation="1"
                  v-bind="{ ...attrsTooltip }"
                  v-on="{ ...onTooltip }"
                  :disabled="loading"
                  @click.stop="openCifDialog({}, 'create-csv')"
                >
                  <v-icon>mdi-file-delimited</v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('companiesActions.title.create-csv') }}
              </div>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="
                isSuperAdminUser ||
                isSuperOperatorUser ||
                isAdminAndCanManageCompanies
              "
            >
              <template
                v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }"
              >
                <v-btn
                  class="primary secondary--text ma-1"
                  fab
                  elevation="1"
                  small
                  v-bind="{ ...attrsTooltip }"
                  v-on="{ ...onTooltip }"
                  :disabled="loading"
                  @click.stop="openCifDialog({}, 'create')"
                  id="create-company"
                >
                  <v-icon>mdi-office-building-plus</v-icon>
                </v-btn>
              </template>
              <div>
                {{ $t('companiesActions.title.create') }}
              </div>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
      <v-list-item>
        <v-list-item-content>
          <v-row class="pa-3">
            <v-text-field
              class="mr-1"
              v-model="search"
              prepend-icon="mdi-magnify"
              clearable
              :label="$t('searchCifs')"
              single-line
              hide-details
              :disabled="loading"
            />
            <v-checkbox
              v-if="
                isSuperAdminUser ||
                isSuperOperatorUser ||
                isAdminAndCanManageCompanies
              "
              class="ml-1"
              id="exclude-delete-checkbox"
              v-model="toDeleteExclude"
              :label="$t('excludeDelete')"
              :disabled="loading"
            />
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <AppSkeleton :condition="loading" type="operatorList">
        <template v-slot:skeleton-content>
          <v-list height="450" class="overflow-y-auto">
            <v-list-item
              :key="i"
              v-for="(cif, i) in pageCifs"
              @click.stop="openCifDialog(cif, 'display')"
              :style="{
                opacity: cif.deletionDate ? '0.6' : '1',
              }"
            >
              <v-row
                :class="isMobile ? 'mb-3' : ''"
                justify="center"
                align="center"
              >
                <v-col v-if="!isMobile" cols="1" md="auto">
                  <v-list-item-icon class="my-3 mr-4">
                    <AppAvatar
                      :userName="cif.empresa"
                      :avatarColor="cif.isBigReceiver ? 'orange' : 'primary'"
                      initialsColor="secondary"
                      avatarSize="40"
                      iconSize="24"
                    />
                  </v-list-item-icon>
                </v-col>
                <v-col cols="4" md="3">
                  <v-list-item-content style="max-width: 300px">
                    <v-list-item-title>
                      <span> {{ cif.empresa }} </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-chip
                        v-if="
                          (cif.granDestinatario || !isNotMainCif(cif)) &&
                          !isClienteDespachoUser
                        "
                        x-small
                        color="primary"
                        text-color="secondary"
                        :style="{ opacity: '0.9' }"
                      >
                        {{ $t('gd') }}
                      </v-chip>
                      <span
                        :style="
                          cif.deletionDate
                            ? { opacity: '0.8' }
                            : { opacity: '1' }
                        "
                      >
                        {{ cif.numeroDocIdentidad }}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-col>
                <v-col cols="3" md="3" v-if="cif.deletionDate">
                  <v-list-item-content>
                    <TimerCountdown
                      :starttime="new Date(0)"
                      :endtime="endTime(cif)"
                      :hideMessage="true"
                    />
                  </v-list-item-content>
                </v-col>
                <v-col
                  cols="3"
                  md="3"
                  v-if="
                    (isSuperAdminUser ||
                      isSuperOperatorUser ||
                      isAdminAndCanManageCompanies) &&
                    !cif.deletionDate
                  "
                >
                  <v-list-item-content>
                    <v-row class="pa-2">
                      <v-chip
                        :x-small="isMobile"
                        class="ma-1"
                        :disabled="!cif.notificacionesActivas"
                      >
                        <v-icon>mdi-mailbox</v-icon>
                        <template v-if="cif.users">
                          {{
                            getCifPermissionsCount(cif)
                              .notificacionesActivasCount
                          }}
                        </template>
                        <v-progress-circular
                          indeterminate
                          :size="15"
                          :width="2"
                          v-else
                        />
                      </v-chip>
                      <v-chip
                        :x-small="isMobile"
                        class="ma-1"
                        :disabled="!cif.firmasActivas"
                      >
                        <v-icon>mdi-draw </v-icon>
                        <template v-if="cif.users">
                          {{ getCifPermissionsCount(cif).firmasActivasCount }}
                        </template>
                        <v-progress-circular
                          indeterminate
                          :size="15"
                          :width="2"
                          v-else
                        />
                      </v-chip>
                      <v-chip
                        :x-small="isMobile"
                        class="ma-1"
                        :disabled="!cif.firmasActivas"
                      >
                        <v-icon :id="'company-clients-' + cif.empresa">
                          mdi-account
                        </v-icon>
                        <template>
                          {{
                            cif.allowedToSign
                              ? Object.keys(cif.allowedToSign)?.length
                              : 0
                          }}
                        </template>
                      </v-chip>
                    </v-row>
                  </v-list-item-content>
                </v-col>
                <v-col cols="2" md="3">
                  <v-list-item-content
                    v-if="
                      (isSuperAdminUser ||
                        isSuperOperatorUser ||
                        isAdminAndCanManageCompanies) &&
                      !cif.deletionDate
                    "
                  >
                    <v-row
                      class="pl-2"
                      justify="start"
                      align="start"
                      v-if="cif.users"
                      @click.stop="openCifDialog(cif, 'users')"
                    >
                      <span
                        class="ml-1"
                        v-for="user in cif.users.slice(0, getShowedUsers())"
                        v-bind:key="user.id"
                      >
                        <AppAvatar
                          :userAvatar="user.avatarURL"
                          :userName="`${user.name} ${user.surname}`"
                          avatarColor="primary"
                          initialsColor="secondary"
                          avatarSize="40"
                          iconSize="24"
                        />
                      </span>
                      <span
                        v-if="cif.users?.length > getShowedUsers()"
                        class="ml-1"
                      >
                        <AppAvatar
                          :userName="getMoreUsers(cif.users, getShowedUsers())"
                          avatarSize="40"
                          iconSize="24"
                          avatarColor="#DCDDE2"
                          initialsColor="black"
                        />
                      </span>
                    </v-row>
                    <v-progress-circular
                      indeterminate
                      :size="15"
                      :width="2"
                      v-else
                    />
                  </v-list-item-content>
                </v-col>
                <v-col cols="3" md="2">
                  <v-row justify="end" no-gutters>
                    <!-- Warning icon -->
                    <v-tooltip
                      bottom
                      v-if="
                        (isSuperAdminUser ||
                          isSuperOperatorUser ||
                          isAdminAndCanManageCompanies ||
                          !isEmpresaUser) &&
                        (needsAcceptanceWallet(cif) || needsAcceptanceGD(cif))
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mx-2"
                          v-bind="attrs"
                          v-on="on"
                          color="warningLow"
                        >
                          mdi-alert
                        </v-icon>
                      </template>
                      <div>
                        {{
                          needsAcceptanceWallet(cif) && needsAcceptanceGD(cif)
                            ? $t('warningCertificateBoth')
                            : needsAcceptanceWallet(cif)
                            ? $t('warningCertificateWallet')
                            : $t('warningCertificateGD')
                        }}
                      </div>
                    </v-tooltip>
                    <v-tooltip
                      bottom
                      v-else-if="
                        (isSuperAdminUser ||
                          isSuperOperatorUser ||
                          isAdminAndCanManageCompanies) &&
                        hasWarningCertificateGD(cif)
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mx-2"
                          v-bind="attrs"
                          v-on="on"
                          :color="certificateColor(cif)"
                        >
                          mdi-alert
                        </v-icon>
                      </template>
                      <div>
                        {{ certificateTitle(cif) }}
                      </div>
                    </v-tooltip>

                    <!-- GD Client actions -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="isClientGD(cif)"
                          class="ma-1"
                          elevation="1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          id="-client-updateGD"
                          @click.stop="openCifDialog(cif, 'updateGD')"
                        >
                          <v-icon>mdi-file-document-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <div>
                        {{ $t('companiesActions.title.updateGD') }}
                      </div>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="isClientGD(cif)"
                          class="ma-1"
                          elevation="1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          id="-client-delete-certificateGD"
                          @click.stop="openClientDialogGD(cif, 'deleteGD')"
                        >
                          <v-icon>mdi-delete-variant</v-icon>
                        </v-btn>
                      </template>
                      <div>
                        {{ $t('clientActions.title.deleteGD') }}
                      </div>
                    </v-tooltip>

                    <!-- Wallet Client actions -->
                    <template
                      v-if="isClientWallet(cif) && needsAcceptanceWallet(cif)"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <!-- Button -->
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            class="mx-2"
                            @click.stop="
                              openClientEditCertificateDialog(cif, 'accept')
                            "
                          >
                            <v-icon color="green"> mdi-check </v-icon>
                          </v-btn>
                        </template>
                        <!-- Tooltip message -->
                        <div>
                          {{ $t('acceptResponsibilityWallet') }}
                        </div>
                      </v-tooltip>

                      <!-- Button to deny responsibility with tooltip -->
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <!-- Button -->
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            class="mx-2"
                            @click.stop="
                              openRemoveCertificateDialog(cif, 'deny')
                            "
                          >
                            <v-icon color="red"> mdi-window-close </v-icon>
                          </v-btn>
                        </template>
                        <!-- Tooltip message -->
                        <div>
                          {{ $t('denyResponsibility') }}
                        </div>
                      </v-tooltip>
                    </template>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="
                            isClientWallet(cif) && !needsAcceptanceWallet(cif)
                          "
                          class="ma-1"
                          elevation="1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          id="edit-client-certificate"
                          @click.stop="
                            openClientEditCertificateDialog(cif, 'edit')
                          "
                        >
                          <v-icon> mdi-notebook-edit-outline </v-icon>
                        </v-btn>
                      </template>
                      <div>
                        {{ $t('clientActions.title.edit') }}
                      </div>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="isClientWallet(cif) && !accepted(cif)"
                          class="ma-1"
                          elevation="1"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          id="-client-delete-certificate"
                          @click.stop="
                            openRemoveCertificateDialog(
                              cif,
                              'deleteCertificate'
                            )
                          "
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <div>
                        {{ $t('clientActions.title.deleteCertificate') }}
                      </div>
                    </v-tooltip>

                    <template
                      v-if="
                        isSuperAdminUser ||
                        isSuperOperatorUser ||
                        isAdminAndCanManageCompanies
                      "
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            class="ma-1"
                            elevation="1"
                            icon
                            v-on="on"
                            :id="'force-delete-cif-' + cif.numeroDocIdentidad"
                            v-show="cif.deletionDate"
                            @click.stop="openCifDialog(cif, 'forceDelete')"
                          >
                            <v-icon>mdi-delete-forever</v-icon>
                          </v-btn>
                        </template>
                        <div>
                          {{ $t('companiesActions.title.forceDelete') }}
                        </div>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ma-1"
                            elevation="1"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            id="delete-cif"
                            v-show="cif.deletionDate"
                            @click.stop="openCifDialog(cif, 'cancelDelete')"
                          >
                            <v-icon>mdi-delete-restore</v-icon>
                          </v-btn>
                        </template>
                        <div>
                          {{ $t('companiesActions.title.cancelDelete') }}
                        </div>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ma-1"
                            elevation="1"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            :id="'delete-cif-' + cif.numeroDocIdentidad"
                            v-show="!cif.deletionDate && isNotMainCif(cif)"
                            @click.stop="openCifDialog(cif, 'delete')"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <div>
                          {{ $t('companiesActions.title.delete') }}
                        </div>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ma-1"
                            elevation="1"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            v-show="cif.firmasActivas"
                            @click.stop="openClientsDialog(cif)"
                          >
                            <v-icon id="open-clients">mdi-account</v-icon>
                          </v-btn>
                        </template>
                        <div>
                          {{ $t('signers') }}
                        </div>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ma-1"
                            elevation="1"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            id="export-data"
                            v-show="!cif.deletionDate"
                            @click.stop="openCifExportDialog(cif, 'exportCif')"
                          >
                            <v-icon>mdi-file-export</v-icon>
                          </v-btn>
                        </template>
                        <div>
                          {{ $t('companiesActions.title.exportCif') }}
                        </div>
                      </v-tooltip>
                    </template>
                  </v-row>
                </v-col>
              </v-row>

              <!-- Button to accept responsibility with tooltip -->
            </v-list-item>
          </v-list>
        </template>
      </AppSkeleton>
      <v-divider />
      <v-card-actions class="headline justify-end" style="max-height: 50px">
        <PaginateBar
          :initialPageSize="10"
          :pageSizes="[10, 25, 50]"
          :search="search"
          :items="filteredCifs"
          @setPage="(value) => (page = value)"
          @setPageSize="(value) => (pageSize = value)"
        />
      </v-card-actions>
    </v-card>

    <CifDialog
      :cifs="cifs"
      :groups="groups"
      :groupMap="groupMap"
      :cif="selectedCif"
      :action="action"
      :title="'companiesActions.title.' + action"
      :notificationsMaxCifs="notificationsMaxCifs"
      :cifsCountNotifications="cifsCountNotifications"
      @closeCifDialog="closeCifDialog"
      v-if="cifDialog"
    />

    <!-- Dialog for the export data -->
    <CifExportDialog
      :cifs="cifs"
      :cif="selectedCif"
      :action="action"
      :title="'companiesActions.title.' + action"
      :toDeleteExclude="toDeleteExclude"
      @closeCifExportDialog="closeCifExportDialog"
      v-if="cifExportDialog"
    />

    <!-- Dialog for the client to upload a certificate -->
    <ClientEditCertificate
      v-if="acceptAuthorizationDialog && isClienteDespachoUser"
      :cif="selectedCif"
      :action="action"
      @closeAcceptAuthorizationDialog="closeAcceptAuthorizationDialog"
    />

    <!-- Dialog for the client to upload a certificate -->
    <RemoveCertificateDialog
      v-if="removeCertificateDialog && isClienteDespachoUser"
      :cif="selectedCif"
      :action="action"
      @closeDenyAuthorizationDialog="closeDenyAuthorizationDialog"
    />

    <!-- Dialog to see the clients that are in a company -->
    <ClientsDialog
      v-if="
        clientsDialog &&
        (isSuperAdminUser ||
          isSuperOperatorUser ||
          isAdminAndCanManageCompanies)
      "
      :cif="selectedCif"
      :tooManyCerts="tooManyCerts"
      @closeClientsDialog="closeClientsDialog"
    />
    <ClientDialogGD
      v-if="clientDialogGD"
      :cif="selectedCif"
      :action="action"
      @closeClientDialog="closeClientDialogGD"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CifDialog from '@/components/cifs/CifDialog'
import CifExportDialog from './CifExportDialog.vue'
import ClientEditCertificate from '@/components/cifs/clients/ClientEditCertificate'
import RemoveCertificateDialog from '@/components/cifs/clients/RemoveCertificateDialog'
import ClientsDialog from '@/components/cifs/clients/ClientsDialog'
import ClientDialogGD from './clients/ClientDialogGD'
import DialogButton from '@/components/documentation/buttons/DialogButton'
import TimerCountdown from '../commons/TimerCountdown.vue'
import AppPlanProgress from '@/components/commons/AppPlanProgress'
import PaginateBar from '@/components/commons/PaginateBar'
import { ninetyDays } from '@/utils/date-utils'
import { certificateColor, certificateTitle } from '@/utils/cert-utils'

export default {
  props: {
    cifs: Array,
    groups: Array,
    groupMap: Object,
    notificationsMaxCifs: Number,
    signaturesMaxCerts: Number,
    loading: Boolean,
  },
  components: {
    CifDialog,
    CifExportDialog,
    ClientEditCertificate,
    RemoveCertificateDialog,
    ClientsDialog,
    ClientDialogGD,
    DialogButton,
    TimerCountdown,
    AppPlanProgress,
    PaginateBar,
  },
  data() {
    return {
      page: 0,
      pageSize: 0,
      search: '',
      selectedCif: undefined,
      action: '',
      toDeleteExclude: true,
      cifDialog: false,
      cifExportDialog: false,
      acceptAuthorizationDialog: false,
      removeCertificateDialog: false,
      clientsDialog: false,
      clientDialogGD: false,
      refresh: false,
    }
  },
  methods: {
    ...mapMutations(['setSnackbar']),
    openClientsDialog(cif) {
      this.clientsDialog = true
      this.selectedCif = cif
    },
    openClientDialogGD(cif, action) {
      this.clientDialogGD = true
      this.selectedCif = cif
      this.action = action
    },
    openRemoveCertificateDialog(cif, action) {
      this.removeCertificateDialog = true
      this.selectedCif = cif
      this.action = action
    },
    openClientEditCertificateDialog(cif, action) {
      this.acceptAuthorizationDialog = true
      this.selectedCif = cif
      this.action = action
    },
    openCifDialog(cif, action) {
      this.action = action
      this.selectedCif = cif
      this.cifDialog = true
    },
    openCifExportDialog(cif, action) {
      this.action = action
      this.selectedCif = cif
      this.cifExportDialog = true
    },
    closeClientsDialog() {
      this.clientsDialog = false
      this.selectedCif = undefined
    },
    closeClientDialogGD() {
      this.clientDialogGD = false
      this.selectedCif = undefined
    },
    closeAcceptAuthorizationDialog() {
      this.acceptAuthorizationDialog = false
      this.selectedCif = undefined
    },
    closeDenyAuthorizationDialog() {
      this.removeCertificateDialog = false
      this.selectedCif = undefined
    },
    closeCifDialog() {
      this.selectedCif = undefined
      this.action = ''
      this.cifDialog = false
    },
    closeCifExportDialog() {
      this.selectedCif = undefined
      this.action = ''
      this.cifExportDialog = false
    },
    getShowedUsers() {
      return this.isMobile ? 1 : 5
    },
    getMoreUsers(items, alreadyShowed) {
      return '+ ' + (items.length - alreadyShowed).toString()
    },
    endTime(cif) {
      if (cif.deletionDate) {
        const fecha = new Date(0)
        fecha.setUTCSeconds(cif.deletionDate)
        return fecha
      }
      return new Date(0)
    },
    getCifPermissionsCount(cif) {
      let filteredUsers = cif.users?.filter(
        (user) =>
          user.notificacionesActivas &&
          user.cifsPermissions[cif.numeroDocIdentidad]?.notificacionesActivas
      )
      // Usar la propiedad length del nuevo array para obtener el número de objetos filtrados
      const notificacionesActivasCount = filteredUsers.length

      filteredUsers = cif.users.filter(
        (user) =>
          user.firmasActivas &&
          user.cifsPermissions[cif.numeroDocIdentidad]?.firmasActivas
      )
      // Usar la propiedad length del nuevo array para obtener el número de objetos filtrados
      const firmasActivasCount = filteredUsers.length
      return { firmasActivasCount, notificacionesActivasCount }
    },
    isClientGD(cif) {
      return (
        this.isClienteDespachoUser &&
        this.$store.state.user.cifsPermissions[cif.numeroDocIdentidad]
          ?.notificacionesActivas
      )
    },
    isClientWallet(cif) {
      return (
        this.isClienteDespachoUser &&
        this.$store.state.user.cifsPermissions[cif.numeroDocIdentidad]
          ?.firmasActivas
      )
    },
    /**
     * It returns true or false depending if the user logged is cliente or admin.
     * Admin case: Checks if all the clients have accepted.
     * Client case: Checks if he has accepted.
     * @param {Object} cif Company's object.
     */
    needsAcceptanceWallet(cif) {
      if (this.isClienteDespachoUser) {
        const allowed = Object.keys(cif.allowedToSign)?.find(
          (clientId) => clientId === this.$store.state.user.id
        )
        return this.isClientWallet(cif) ? !cif.allowedToSign[allowed] : false
      }
      // Checking if all the clients have accepted
      else
        return cif.allowedToSign
          ? Object.keys(cif.allowedToSign).some(
              (clientId) => !cif.allowedToSign[clientId]
            )
          : false
    },
    needsAcceptanceGD(cif) {
      return (
        !this.isEmpresaUser &&
        cif.granDestinatario &&
        !cif.certificateExpiration &&
        this.isNotMainCif(cif)
      )
    },
    hasWarningCertificateGD(cif) {
      if (!cif.granDestinatario || !this.isNotMainCif(cif)) return false

      const certificateExpiration = cif.certificateExpiration

      if (!certificateExpiration) return false

      const certExpiration = certificateExpiration
        .toDate()
        .toISOString()
        .slice(0, 10)

      return certExpiration < ninetyDays()
    },
    certificateColor(cif) {
      return certificateColor(cif.certificateExpiration.toDate())
    },
    certificateTitle(cif) {
      return certificateTitle(cif.certificateExpiration.toDate())
    },
    /**
     * Checks if the cif is the main cif of the user.
     * @param {Object} cif Cif to check.
     */
    isNotMainCif(cif) {
      return (
        cif.id !==
        (this.isOperatorUser
          ? this.$store.state.user.parentRef.id
          : this.$store.state.user.id)
      )
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isAdminUser',
      'isSuperAdminUser',
      'isSuperOperatorUser',
      'isAdminAndCanManageCompanies',
      'isInGroupUser',
      'isNotificacionesActivasEmpresa',
      'isFirmasActivasEmpresa',
      'isOperatorUser',
      'isEmpresaUser',
      'isClienteDespachoUser',
    ]),
    /**
     * Computed property that recomputes and calls needsAcceptance
     */
    accepted() {
      return (cif) => this.needsAcceptanceWallet(cif)
    },
    cifsCount() {
      if (this.toDeleteExclude)
        return this.cifs.filter((c) => !c.deletionDate).length
      else return this.cifs.length
    },
    cifsCountNotifications() {
      if (!this.isEmpresaUser) {
        return this.cifs.reduce((acc, cif) => {
          return (
            acc +
            (cif.notificacionesActivas ? (cif.granDestinatario ? 10 : 1) : 0)
          )
        }, 0)
      } else {
        return this.cifs.filter((c) => c.notificacionesActivas).length
      }
    },
    certsCountSignatures() {
      return this.cifs.reduce(
        (acc, cif) =>
          acc + (cif.allowedToSign ? Object.keys(cif.allowedToSign).length : 0),
        0
      )
    },
    tooManyCerts() {
      return this.certsCountSignatures >= this.signaturesMaxCerts
    },
    filteredCifs() {
      const searchUpperCase = this.search ? this.search.toUpperCase() : ''
      let filtered = this.cifs?.filter(
        (cif) =>
          cif.numeroDocIdentidad?.toUpperCase().includes(searchUpperCase) ||
          cif.empresa?.toUpperCase().includes(searchUpperCase) ||
          cif.email?.toUpperCase().includes(searchUpperCase) ||
          cif.telefono?.toUpperCase().includes(searchUpperCase)
        // cif?.users
        //   ?.map((user) => user.name)
        //   ?.some((user) => user.toUpperCase().includes(searchUpperCase))
      )
      if (this.toDeleteExclude) {
        filtered = filtered?.filter((cif) => !cif.deletionDate)
      } else {
        const notToDelete = filtered?.filter((cif) => !cif.deletionDate)
        const toDelete = filtered?.filter((cif) => cif.deletionDate)
        filtered = [...toDelete, ...notToDelete]
      }

      return filtered
    },
    pageCifs() {
      return this.filteredCifs.slice(
        this.page * this.pageSize,
        (this.page + 1) * this.pageSize
      )
    },
  },
}
</script>
<style>
.soft-grey-1 {
  color: '#BDBDBD';
}
.soft-grey-2 {
  color: '#757575';
}
.hard-grey-1 {
  color: '#616161';
}
.hard-grey-2 {
  color: '#424242';
}
</style>
